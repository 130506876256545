.forgeteyeiconcp {
    position: absolute;
    right: 30px;
    transition-duration: 0s;
    top: 298px;
}

.forgeteyeiconp {
    position: absolute;
    right: 30px;
    transition-duration: 0s;
    top: 375px;
}

.forgetbackimg{
    color: rgba(7, 75, 137, 1);
    font-family: 'Roboto-Regular';
    font-size: 15px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
  }