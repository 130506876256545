/* Page.css */
/* Style the scrollbar track (the area behind the scrollbar) */
.scrollable-text {
    max-height: 470px !important;
    /* Set a maximum height to make the content scrollable */
    overflow-y: scroll !important;
    /* Enable vertical scrolling */
  }
  
  .listinput {
    border-radius: 25px;
    height: 32px;
    margin-bottom: 4px;
  }
  
  .headerlogo {
    display: flex;
    background-color: white;
    height: 57px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    justify-content: space-between;
  }
  
  .mainlogo {
    height: 25px;
    cursor: pointer;
    margin: 15px;
  }
  
  .candidateDetails .profile-image1 {
    /* width: 100px;
    height: 100px; */
    border-radius: 60%;
    overflow: hidden;
    margin: 150px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  
  .profile-name {
    font-family: 'Roboto-Regular';
    font-size: 13px;
  }
  
  .listbtncss {
    background-color: rgba(7, 75, 137, 1) !important;
  }
  
  .backcss{
    height: 13px;
  }
  
  .backimg{
    color: rgba(7, 75, 137, 1);
    font-family: 'Roboto-Regular';
    font-size: 13px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  
  .btnflex{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .profileheader {
    display: flex;
    margin-right: 50px;
    align-items: center;
  }

  .candidateDetails .profileimg{
    width: 100%;
    height: 100%;
  }
  
  .profileimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .scrollable-text::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  .scrollable-text::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 4px;
  }
  
  /* Handle */
  .scrollable-text::-webkit-scrollbar-thumb {
    background: rgba(19, 73, 138, 1);
    border-radius: 4px;
  }
  
  /* Handle on hover */
  .scrollable-text::-webkit-scrollbar-thumb:hover {
    background: rgba(19, 73, 138, 1);
  }
  
  
  /* Page.css */
  .input-container {
    position: relative;
  }

  .candidateDetails .backimg{
    color: rgba(7, 75, 137, 1);
    font-family: 'Roboto-Regular';
    font-size: 13px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  
  .input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .custom-input {
    flex: 1;
    padding-right: 30px;
    /* Adjust the padding to make room for the clear icon */
  }
  
  .clear-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    /* Adjust the distance from the right edge */
    transform: translateY(-50%);
    cursor: pointer;
  }


  .usernamecss{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Namecss{
    font-family: 'Roboto-Bold';
    font-size: 15px;
  }

  .profileDetail{
    font-family: 'Roboto-Bold';
    font-size: 18px;
  }

  .profile{
    font-family: 'Roboto-Regular';
    font-size: 14px;
  }

  .otherimage{
    height: 14px;
    margin-right: 7px;
  }
  .location{
    height: 17px;
    margin-right: 7px;
  }

  .score{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin: 10px;
  }

  .scoretext{
    font-family: 'Roboto-Regular';
    font-size: 15px;
    margin: 10px;
    padding: 10px;
    background-color: rgba(76, 175, 80, 0.13);
    color: rgba(76, 175, 80, 1);
  }

  .contactDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }

.rowborder{
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.devcss{
  font-family: 'Roboto-Bold';
  font-size: 13px;
}
.devtext{
  font-family: 'Roboto-Regular';
  font-size: 13px;
}

.skills{
  background-color: rgba(237, 235, 235, 1);
    width: auto;
    border-radius: 10px;
    font-family: 'Roboto-Regular';
    font-size: 14px;
}